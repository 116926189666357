.mobile-menu-window {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #121212;
    z-index: 1000;
}

.item {
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px;
  border-color: antiquewhite;
  color: #F9F9F9;
}

.footer {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -50%);
  width: 90%;
}

.footer-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 18.89px;
  color: #797979;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
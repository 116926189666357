.reversed-image {
  transform: scaleX(-1);
}
@media only screen and (min-width: 900px) {
  .about-us-icon-over-ellipse {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .about-us-icons {
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media only screen and (max-width: 900px) {
  .about-us-icon-over-ellipse {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .about-us-icons {
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media only screen and (max-width: 700px) {
  .about-us-icon-over-ellipse {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .about-us-icons {
    padding-left: 75px;
    padding-right: 75px;
  }
}
@media only screen and (max-width: 550px) {
  .about-us-icon-over-ellipse {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .about-us-icons {
    padding-left: 35px;
    padding-right: 35px;
  }
}

.bola-bg-image {
  background-image: url("../../../public/assets/review_bg.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  filter: brightness(30%);
}
.bola-text {
  filter: brightness(100%);
  color: "#fff";
  z-index: 2;
}
.reviews-title {
  font-size: 2.1875rem;
  font-weight: 600;
  line-height: 2.5625rem;
  letter-spacing: 0.02em;
}
.align-center {
  top: 10%;
  left: 10%;
  transform: translate(-5%, -5%);
}
.review-subtitle {
  font-size: 1rem;
  font-weight: 100;
  line-height: 1.3rem;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.review-text {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.01em;
  text-align: left;
  color: black;
}
.review-profile-name {
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.2rem;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}
.review-profile-address {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.8rem;
  letter-spacing: 0em;
  text-align: left;
  color: #5b5b5b;
}

@media only screen and (min-width: 900px) {
  .trust-subtext {
    padding-top: 80px;
    padding-right: 80px;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
  }
}
@media only screen and (max-width: 900px) {
  .trust-subtext {
    padding-top: 40px;
    padding-right: 40px;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
  }
}
@media only screen and (max-width: 700px) {
  .trust-subtext {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
  }
}

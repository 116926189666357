@media only screen and (min-width: 900px) {
  .contact-main-text {
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 2.25rem;
  }
  .contact-main-subtext {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.32rem;
  }

  /* contact-info */
  .contact-info-heading {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.875rem;
  }
  .contact-info-subheading {
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 1.06rem;
  }
  .contact-info-option {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
  }
  .contact-info-social {
    top: 2px;
    left: 6%;
  }
  /* contact-form */
  .contact-input-box {
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-radius: 0px;
    border-bottom-color: black;
    width: max-content;
  }
  .contact-input-label {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.25rem;
  }
}

@media only screen and (max-width: 900px) {
  .contact-main-text {
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 2.25rem;
  }
  .contact-main-subtext {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.32rem;
  }
  /* contact-info */
  .contact-info-heading {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.875rem;
  }
  .contact-info-subheading {
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 1.06rem;
  }
  .contact-info-option {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
  }
  .contact-info-social {
    top: 2px;
    left: 20%;
  }
  /* contact-form */
  .contact-input-box {
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-radius: 0px;
    border-bottom-color: black;
    width: max-content;
  }
  .contact-input-label {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.25rem;
  }
}

@media only screen and (max-width: 765px) {
  .contact-main-text {
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 2.25rem;
  }
  .contact-main-subtext {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.32rem;
  }
  /* contact-info */
  .contact-info-heading {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.875rem;
  }
  .contact-info-subheading {
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 1.06rem;
  }
  .contact-info-option {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
  }
  .contact-info-social {
    top: 2px;
    left: 40%;
  }
  /* contact-form */
  .contact-input-box {
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-radius: 0px;
    border-bottom-color: black;
    width: max-content;
  }
  .contact-input-label {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.25rem;
  }
}

@media only screen and (max-width: 500px) {
  .contact-main-text {
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 2.25rem;
  }
  .contact-main-subtext {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.32rem;
  }
  /* contact-info */
  .contact-info-heading {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.875rem;
  }
  .contact-info-subheading {
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 1.06rem;
  }
  .contact-info-option {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
  }
  .contact-info-social {
    top: 2px;
    left: 30%;
  }
  /* contact-form */
  .contact-input-box {
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-radius: 0px;
    border-bottom-color: black;
    width: max-content;
  }
  .contact-input-label {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.25rem;
  }
}

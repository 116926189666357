@media screen and (min-width: 900px) {
  .ride-main-heading {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.5rem;
  }
  .ride-main-subheading {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.8rem;
    letter-spacing: 0.01em;
  }
}
@media screen and (max-width: 900px) {
  .ride-main-heading {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 3rem;
  }
  .ride-main-subheading {
    font-size: 1.15rem;
    font-weight: 400;
    line-height: 1.6rem;
    letter-spacing: 0.01em;
  }
}
@media screen and (max-width: 700px) {
  .ride-main-heading {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 3rem;
  }
  .ride-main-subheading {
    font-size: 1.15rem;
    font-weight: 400;
    line-height: 1.6rem;
    letter-spacing: 0.01em;
  }
}

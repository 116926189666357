.app-store-heading {
  font-size: 1.6875rem;
  font-weight: 600;
  line-height: 2.3125rem;
  letter-spacing: 0.02em;
  text-align: left;
}
.app-store-subheading {
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.01em;
  text-align: left;
}

@media only screen and (min-width: 900px) {
  .hero-main-text {
    font-size: 1.56rem;
    line-height: 2.35rem;
    font-weight: 700;
  }
  .hero-main-subtext {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.2rem;
  }
  .car-icon {
    top: 0%;
    left: 5%;
  }
  .mission-subtext {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .about-topics-heading {
    font-size: 1.6875rem;
    font-weight: 600;
    line-height: 1.875rem;
    text-align: center;
  }
  .value-points-text {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2rem;
  }
  .value-points-subtext {
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 1.25rem;
  }
  .values-internal-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-width: 900px) {
  .hero-main-text {
    font-size: 1.56rem;
    line-height: 2.35rem;
    font-weight: 700;
  }
  .hero-main-subtext {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.8rem;
  }
  .car-icon {
    top: 10%;
    left: 5%;
  }
  .mission-subtext {
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 1.25rem;
  }
  .about-topics-heading {
    font-size: 1.6875rem;
    font-weight: 600;
    line-height: 1.875rem;
    text-align: center;
  }
  .value-points-text {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2rem;
  }
  .value-points-subtext {
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 1.25rem;
  }
  .values-internal-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-width: 700px) {
  .hero-main-text {
    font-size: 1.56rem;
    line-height: 2.35rem;
    font-weight: 700;
  }
  .hero-main-subtext {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.8rem;
  }
  .car-icon {
    top: 40%;
    left: 10%;
  }
  .mission-subtext {
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 1.25rem;
  }
  .about-topics-heading {
    font-size: 1.6875rem;
    font-weight: 600;
    line-height: 1.875rem;
    text-align: center;
  }
  .value-points-text {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2rem;
  }
  .value-points-subtext {
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 1.25rem;
  }
  .values-internal-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.page-content {
    background-color: white; /* Background color for the modal content */
    color: black; /* Text color for the modal content */
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    margin: auto;
}

.custom-table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #000; /* Add a border to the table */
  }
  
  .custom-table th,
  .custom-table td {
    border: 1px solid #000; /* Add a border to table cells */
    padding: 8px;
    text-align: left;
  }
  
  .custom-table th {
    background-color: #f2f2f2; /* Add a background color to table headers */
  }
  
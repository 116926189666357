@media only screen and (min-width: 900px) {
  .home-hero-main-text {
    letter-spacing: 0.02rem;
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-weight: 900;
    font-stretch: extra-expanded;
  }
  .home-hero-main-subtext {
    font-size: 1rem;
    line-height: 1.625rem;
    font-weight: 600;
  }
  .home-hero-main-btn {
    font-size: 1.1rem;
    font-weight: 900;
  }
  .home-hero-main-div {
    top: 18%;
    left: 10%;
  }
}

@media only screen and (max-width: 900px) {
  .home-hero-main-text {
    font-size: 1.7rem;
    line-height: 2.5rem;
    font-weight: 900;
    font-stretch: extra-expanded;
    color: #000;
    letter-spacing: 1px;
  }
  .home-hero-main-subtext {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
  }
  .home-hero-main-btn {
    font-size: 0.9rem;
    font-weight: 700;
  }
  .home-hero-main-div {
    top: 30%;
    left: 10%;
  }
}

@media only screen and (max-width: 600px) {
  .home-hero-main-text {
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 900;
    font-stretch: extra-expanded;
    color: #000;
    letter-spacing: 1px;
  }
  .home-hero-main-subtext {
    font-size: 0.9rem;
    line-height: 1.35rem;
    font-weight: 400;
  }
  .home-hero-main-btn {
    font-size: 0.7rem;
    font-weight: 700;
  }
  .home-hero-main-div {
    top: 15%;
    left: 7%;
  }
}

@media only screen and (min-width: 900px) {
  .footer-subtext {
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.2rem;
    color: #d9d9d9;
    padding-right: 25%;
    padding-left: 25%;
    text-align: center;
  }
  .footer-input {
    padding-left: 25%;
    padding-right: 25%;
  }
  .social-icons {
    top: -35px;
    left: 0%;
  }
  .align-center {
    top: 10%;
    left: 10%;
    transform: translate(-5%, 10%);
  }
}

@media only screen and (max-width: 900px) {
  .footer-subtext {
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.2rem;
    color: #d9d9d9;
    padding-right: 25%;
    padding-left: 25%;
    text-align: center;
  }
  .footer-input {
    padding-left: 30%;
    padding-right: 30%;
  }
  .social-icons {
    top: -35px;
    left: 0%;
  }
  .align-center {
    top: 10%;
    left: 10%;
    transform: translate(-5%, 10%);
  }
}

@media only screen and (max-width: 750px) {
  .footer-subtext {
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.2rem;
    color: #d9d9d9;
    padding-right: 20%;
    padding-left: 20%;
  }
  .footer-input {
    padding-left: 20%;
    padding-right: 20%;
  }
  .social-icons {
    top: -35px;
    left: 30%;
  }
  .align-center {
    top: 10%;
    left: 10%;
    transform: translate(-5%, 10%);
  }
}

@media only screen and (max-width: 600px) {
  .footer-subtext {
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.2rem;
    color: #d9d9d9;
    padding-right: 10%;
    padding-left: 10%;
  }
  .footer-input {
    padding-left: 10%;
    padding-right: 10%;
  }
  .social-icons {
    top: -35px;
    left: 30%;
  }
  .align-center {
    top: 10%;
    left: 10%;
    transform: translate(-5%, 10%);
  }
}
